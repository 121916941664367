import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import './styles.css';
import {number, object} from "prop-types";
import {
    getAnalysisGroups,
    getCompanyMeasures,
    getConfig,
    getEConsultAnalysis,
    getEConsultPdf,
} from "../../service/react-service";
import Chart from "react-google-charts";

const EConsultantWrapper = ({companyId, state, companyConsultationState}) => {
    const {t} = useTranslation();
    const [measures, setMeasures] = useState([]);
    const [measureSelect, setMeasureSelect] = useState(false)
    const [config, setConfig] = useState({});
    const [selectedMeasures, setSelectedMeasures] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.selectedMeasures) ? companyConsultationState.selectedMeasures : []);
    const [selectedAnalysisGroups, setSelectedAnalysisGroups] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.selectedAnalysisGroups) ? companyConsultationState.selectedAnalysisGroups : []);
    const [resultAnalysisGroup, setResultAnalysisGroup] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.resultAnalysisGroup) ? companyConsultationState.resultAnalysisGroup : false);
    const [isCumulative, setIsCumulative] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.isCumulative) ? companyConsultationState.isCumulative : false);
    const [analysisGroups, setAnalysisGroups] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.analysisGroups) ? companyConsultationState.analysisGroups : []);
    const [analysisItems, setAnalysisItems] = useState((companyConsultationState.toDrill && companyId === companyConsultationState.id && companyConsultationState.analysisItems) ? companyConsultationState.analysisItems : []);
    const [filterTheseMeasures, setFilteringMeasures] = useState(['Liikevaihto',
        'Tilikauden voitto (tappio)',
        'Myyntikate',
        'Omavaraisuusaste %',
        'Quick ratio',
        'Tilikauden pituus',
        'Lyhytaikainen vieras pääoma',
        'Pitkäaikainen vieras pääoma',
        'Korolliset velat',
        'Sijoitettu pääoma',
        'Käyttökate',
        'Rahoitustulos',
        'Rahoitustulos %',
        'Liikevoitto %',
        'Nettotulos',
        'Myyntikate %',
        'Käyttökate %',
        'Current ratio',
        'Velkaantumisaste',
        'Substanssiarvo',
        'Jalostusarvo',
        'Liikevaihto 12 kk',
        'Sijoitetun pääoman muutos',
        'Pääoman kiertonopeus',
        'Myyntisaamisten kiertoaika (päivää)',
        'Myyntisaamisten kiertoaika arvoksi',
        'Jalostusarvo/Henkilöstökulut',
        'Vaihto-omaisuuden kiertoaika',
        'Ostovelkojen kiertoaika',
    ])
    /** updates react state to angular service --> state is kept intact between view changes */
    const updateStateToService = (consultationTextArray, toDrillNew) => {
        const updatedState = {
            id: companyId,
            selectedMeasures: selectedMeasures,
            isCumulative: isCumulative,
            resultAnalysisGroup: resultAnalysisGroup,
            selectedAnalysisGroups: selectedAnalysisGroups,
            analysisGroups: analysisGroups,
            analysisItems: consultationTextArray,
            toDrill: toDrillNew
        };
        return Object.assign(companyConsultationState, updatedState);
    }

    useEffect(() => {
        if (companyConsultationState.toDrill){
            updateStateToService(analysisItems, false)
        }
        getCompanyMeasures(companyId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                var measuresToBeFiltered = res.data.sort((a, b) => a.id - b.id);
                filterTheseMeasures.forEach(filterer =>{
                    if (measuresToBeFiltered.some(measure => measure.name === filterer)){
                        let index =  measuresToBeFiltered.findIndex(measure => measure.name === filterer)
                        if (index !== -1) {
                            measuresToBeFiltered.splice(index, 1);
                        }
                    }
                });
                setMeasures(measuresToBeFiltered);

            }
        }).catch(err => console.log(err));
        getAnalysisGroups().then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                setAnalysisGroups(res.data);
                getConfig(companyId).then((res2, rej2) => {
                    if (rej2 !== undefined) {
                        console.log(rej2);
                    } else {
                        setConfig(res2.data);
                        if (!res2.data.hasCurrentFiscalYearBudgets){
                            setAnalysisGroups(res.data.filter(group => group.type !== "GROUP_WITH_BUDGET_COMPARISON"));
                        }
                    }
                }).catch(err => console.log(err));
            }
        }).catch(err => console.log(err));


    }, [companyId]);

    const handleMeasuresArray = (measure) => {
        setSelectedMeasures([...selectedMeasures, measure]);
        if (measures.some(item => item.id === measure.id)) {
            let index = measures.indexOf(measure)
            if (index !== -1) {
                measures.splice(index, 1);
            }
        }
        setMeasures(measures);
    }

    const handleSelectedMeasuresRemove = (measure) => {
        if (selectedMeasures.some(item => item.id === measure.id)) {
            let index = selectedMeasures.indexOf(measure)
            if (index !== -1) {
                selectedMeasures.splice(index, 1);
            }
        }
        setMeasures([...measures, measure].sort((a, b) => a.id - b.id));
    }

    const selectAnalysisGroup = (group) => {
        if (group.type === "GROUP_WITH_SELECTED_MEASURES") {
            setResultAnalysisGroup(!resultAnalysisGroup);
        }
        if (selectedAnalysisGroups.includes(group)) {
            setSelectedAnalysisGroups(selectedAnalysisGroups.filter(selectedGroup => selectedGroup.id !== group.id));
        } else {
            setSelectedAnalysisGroups([...selectedAnalysisGroups, group]);
        }
    }

    const parseAnalysisText = (dataItem) => {
        const splitted = dataItem.text.split(' ')
        let fullString = "";
        let htmlArray = [];
        splitted.forEach(item => {
            if (item.startsWith("@lowName")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.measure)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.measure.name.toLowerCase()}</a>)
            } else if (item.startsWith("@name")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.measure)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.measure.name}</a>)
            } else if (item.startsWith("@extraname1")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure1)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure1.name}</a>)
            } else if (item.startsWith("@extraname2")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure2)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure2.name}</a>)
            } else if (item.startsWith("@extraname3")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure3)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure3.name}</a>)
            } else if (item.startsWith("@extraname4")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure4)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure4.name}</a>)
            } else if (item.startsWith("@extraname5")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure5)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure5.name}</a>)
            } else if (item.startsWith("@extraname6")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure6)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure6.name}</a>)
            } else if (item.startsWith("@extraname7")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure7)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure7.name}</a>)
            } else if (item.startsWith("@extraname8")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure8)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure8.name}</a>)
            } else if (item.startsWith("@extraname9")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure9)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure9.name}</a>)
            } else if (item.startsWith("@extra10name")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<a onClick={() => goDrill(dataItem.extraMeasure10)}
                                  key={Math.random() * htmlArray.length + 1}> {dataItem.extraMeasure10.name}</a>)
            } else if (item.startsWith("@chart")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                if ((dataItem.measureValue || dataItem.measureValue == 0) && (dataItem.extraMeasure1Value || dataItem.extraMeasure1Value == 0) && !(dataItem.measureValue == 0 && dataItem.extraMeasure1Value == 0)) {
                    const measurePercent = Math.abs(dataItem.measureValue);
                    const extraMeasurePercent = Math.abs(dataItem.extraMeasure1Value);
                    htmlArray.push(<Chart style={{marginTop:'20px'}} key={Math.random() * htmlArray.length + 1}
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Tunnusluku', 'Arvo'],
                            [dataItem.measure.name, measurePercent],
                            [dataItem.extraMeasure1.name, extraMeasurePercent]
                        ]}
                        options={{
                            title: 'Lainojen jakautuminen',
                        }}
                    />)
                }
            } else if (item.startsWith("@br")) {
                htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString + ' '}</span>)
                fullString = "";
                htmlArray.push(<br></br>)
            }
            else {
                fullString = fullString + ' ' + item;
            }
        })
        htmlArray.push(<span key={Math.random() * htmlArray.length + 1}>{fullString}</span>)
        return htmlArray;
    }

    const goDrill = (measure) => {
        updateStateToService(analysisItems, true)
        state.go('drill', {
            variableid: measure.id,
            monthly: !isCumulative,
            variableType: "MEASURE",
            year: config.actualizedDate.year,
            month: config.actualizedDate.month
        });
    }

    const createAnalysis = async () => {
        let requestArray = [];
        let analysisItems2 = [];
        setAnalysisItems(analysisItems2)
        selectedAnalysisGroups.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        selectedAnalysisGroups.forEach(group => {
            if (group.type === "GROUP_WITH_SELECTED_MEASURES") {
                let measureIds = [];
                selectedMeasures.forEach(measure => {
                    measureIds.push(measure.id);
                })
                requestArray.push({id: group.id, measureIds: measureIds});
            } else {
                requestArray.push({id: group.id, measureIds: []});
            }
        })
        await getEConsultAnalysis(companyId, isCumulative, requestArray).then(response => {
            analysisItems2 = response.data;
            setAnalysisItems(response.data);
        });
        updateStateToService(analysisItems2, false);
    }

    const createPdf = async () => {
        let requestArray = [];
        selectedAnalysisGroups.forEach(group => {
            if (group.type === "GROUP_WITH_SELECTED_MEASURES") {
                let measureIds = [];
                selectedMeasures.forEach(measure => {
                    measureIds.push(measure.id);
                })
                requestArray.push({id: group.id, measureIds: measureIds});
            } else {
                requestArray.push({id: group.id, measureIds: []});
            }
        });
        const pdf = await getEConsultPdf(companyId, requestArray, isCumulative).then((response) => {
            return response.data;
        })
        let pdfWindow = window.open("");
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(pdf) + "'></iframe>"
        );
    }

    const isCreateButtonDisabled = () => {
        if (selectedAnalysisGroups.length > 0 && !selectedAnalysisGroups.includes(2)) {
            return false;
        } else if (selectedAnalysisGroups.includes(2) && selectedMeasures.length > 0) {
            return false;
        }
        return true;
    }

    const isAnalysisGroupSelected = name => {
        let status = false;
        if (selectedAnalysisGroups.length > 0) {
            selectedAnalysisGroups.forEach(it => {
                if (it.name === name) {
                    return status = true;
                }
            })
        }
        return status;
    }

    return (
        <div className="consultationSelection">
            <h1>E-konsultti</h1>
            <br/>
            <div className="panel panel-default"
                 style={{padding: '1.2vw', marginRight: '3vw',  maxHeight: '300px'}}>
                <div className="form-group">
                    <div className="consultationSelect">
                        <div style={{flexDirection: 'column', border: 'solid 1px lightgrey', borderRadius: '5px', paddingBottom:'1vw', marginRight:'1vw'}}>
                            <h3 style={{marginLeft: '1vw'}}>Analyysiryhmät</h3>
                            <div className="consultationItem" style={{
                                marginLeft: '1vw',
                                minHeight: 'auto',
                                maxHeight: '200px'
                            }}>

                                {analysisGroups.map(group => {
                                    return (<div key={group.id}>

                                        <input type="checkbox" id={group.name} name={group.name}
                                                                       checked={isAnalysisGroupSelected(group.name)}
                                                                       style={{marginRight: '4px'}}
                                                                       onChange={() => selectAnalysisGroup(group)}/>
                                        <label htmlFor={group.name}> {group.name}</label></div>)
                                })}
                                <br/>
                                <div>
                                    <input type="checkbox" id="cumulative" name="cumulative" checked={isCumulative}
                                           onChange={() => setIsCumulative(!isCumulative)} style={{padding: '0px',margin:'0px', marginRight: '4px'}} />
                                    <label htmlFor="cumulative">Näytä kumulatiiviset arvot</label>
                                </div>
                            </div>
                            <div style={{marginTop: '1vw', marginLeft: '1vw'}}>
                                <div className="btn-group pull-left">
                                    <button disabled={isCreateButtonDisabled()} type="button"
                                            className="btn btn-primary" onClick={() => createAnalysis()}>Luo analyysi
                                    </button>
                                    <button disabled={isCreateButtonDisabled()} style={{borderRadius: '0 4px 4px 0'}}
                                            type="button" className="btn btn-primary" data-toggle="dropdown">
                                        <span className="caret" />
                                    </button>
                                    <ul className="dropdown-menu" role="menu">
                                        <li role="menuitem">
                                            <a className="btn pull-left">
                                                <span onClick={() => createPdf()}>Luo Pdf</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        {resultAnalysisGroup && measureSelect &&
                        <>
                            <div className="consultationItem">
                                <div className="measureDropdownDiv">
                                    <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                        Valitse Tunnusluvut
                                    </button>
                                    <ul className="dropdown-menu" id="measureDropdown">
                                        {measures.map(measure => {
                                            return <li key={measure.id} onClick={() => handleMeasuresArray(measure)}>
                                                {measure.name}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="consultationItem ">
                                {selectedMeasures.length > 0 ?
                                    <div className={selectedMeasures.length > 9 ? "selectedMeasuresScrollable" : "selectedMeasures"}>
                                        <ul style={{padding: '0 0 1px 0', margin: '0'}}>
                                            {selectedMeasures.map(measure => {
                                                return (
                                                    <div key={measure.id} className='selectedMeasureItem'>
                                                        <li style={{padding: '0 0 1px 0',margin: '0px', marginLeft: '15px'}}>
                                                            {measure.name}
                                                        </li>
                                                        <span style={{paddingLeft: '2px', marginTop: '3px'}}
                                                              className={'glyphicon glyphicon-remove'} onClick={() => {
                                                            handleSelectedMeasuresRemove(measure)
                                                        }}/>
                                                    </div>)
                                            })}
                                        </ul>
                                    </div> : null}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            {analysisItems.length > 0 &&
            <div id="divToPrint">
                <h1>Konsultaatio</h1>
                {analysisItems.map(item => {
                    return (
                        <div key={item.groupId + Math.floor(Math.random() * item.groupId)}>
                            <h3>{analysisGroups.find(group => group.id === item.groupId).name}</h3>
                            {item.data.map((dataItem) => {
                                return <div key={dataItem.measure.name + Math.random()}>
                                    {parseAnalysisText(dataItem)}
                                    <br/><br/>
                                </div>
                            })
                            }
                        </div>
                    )
                })}
            </div>
            }
        </div>
    );
}

EConsultantWrapper.propTypes = {
    companyId: number.isRequired,
    state: object,
    companyConsultationState: object
};

export default EConsultantWrapper;
