'use strict';

import {BasicChartOfAccountsModule} from './basic-chart-of-accounts/basic-chart-of-accounts.module';
import {CompanyAccountChartModule} from './company-account-chart/company-account-chart.module';
import {CompanyBalanceSheetModule} from "./company-balance-sheet/company-balance-sheet.module";
import {BudgetModule} from "./budget2/budget.module";
import {EConsultantModule} from "./e-consultant/e-consultant.module";
import {CompanyIncomeStatementModule} from "./company-income-statement/company-income-statement.module";
import {PerformanceMeasuresModule} from "./performance-measures/performance-measures.module";
import {SelectedCompanyService} from "./selected-company.service";
import {CashFlowModule} from "./cash-flow/cash-flow.module";
import {SharedModule} from "./shared/shared.module";
import {RootScopeWrapperService} from "./root-scope-wrapper.service";
import {BudgetTableModule} from "./budget/budget-table/budget-table.module";
import "./../react-app/i18n/i18n";
import i18next from "i18next";
import {NavbarCalculationAlarmModule} from "../components/navbar/navbar_calculation_alarm.module";

angular.module(
    'ecoeasyApp',
    ['LocalStorageModule', 'tmh.dynamicLocale', 'pascalprecht.translate',
    'ngResource', 'ngCookies', 'ngAria', 'ngCacheBuster', 'ngFileUpload',
    'ui.sortable', 'chart.js', 'ui.select', 'ngSanitize',
    'ui.bootstrap', 'ui.router', 'infinite-scroll', 'angular-loading-bar',
        SharedModule, BasicChartOfAccountsModule, BudgetTableModule, CompanyAccountChartModule,
        CompanyBalanceSheetModule, BudgetModule, CompanyIncomeStatementModule, PerformanceMeasuresModule, CashFlowModule, NavbarCalculationAlarmModule, EConsultantModule])
    .constant('FEATURE_COMPANY_ACCOUNT_CHART', true)
    .constant('FEATURE_CASH_BUDGET', true)
    .constant('FEATURE_KIINTEISTOKIRJURI', true)
    .constant('FEATURE_YHDISTYSKIRJURI', true)
    .constant('FEATURE_SEPARATE_BUDGET_INFO_ROWS', true)
    .constant('FEATURE_SECOND_PHASE_TABS', true)
    .constant('FEATURE_SHOW_BUDGETS_IN_INDICATORS', true)
    .constant('FEATURE_THIRD_PHASE', true)
    .service('RootScopeWrapperService', RootScopeWrapperService)
    .service('SelectedCompanyService', SelectedCompanyService)
    .config(function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, tmhDynamicLocaleProvider, httpRequestInterceptorCacheBusterProvider, AlertServiceProvider) {
        // uncomment below to make alerts look like toast
        //AlertServiceProvider.showAsToast(true);

        //enable CSRF
        $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');
        $stateProvider.state('site', {
            'abstract': true,
            views: {
                'navbar@': {
                    template: require('../components/navbar/navbar.html'),
                    controller: 'NavbarController'
                },
                'navbar_top@': {
                    template: require('../components/navbar/navbar_top.html'),
                    controller: 'NavbarTopController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('company');
                    $translatePartialLoader.addPart('userGroup');

                }]
            }
        });

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'+'?nocache='+(new Date()).getTime()
        });

        $translateProvider.preferredLanguage('fi');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey("NG_TRANSLATE_LANG_KEY");
    })
    .config(function(ChartJsProvider){
        Chart.defaults.global.colors = [
            '#9ecae1',
            '#08519c',
            '#6baed6',
            '#2171b5',
            '#c6dbef',
            '#4292c6',
            '#f7fbff'
        ];
        ChartJsProvider.setOptions({ legend: { display: true} });
    })
    // ADDED BY Digimen Oy
    // We all hate spinners don't we?
    .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    }])
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function ($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name: 'boolean',
            decode: function (val) {
                return val == true ? true : val == "true" ? true : false
            },
            encode: function (val) {
                return val ? 1 : 0;
            },
            equals: function (a, b) {
                return this.is(a) && a === b;
            },
            is: function (val) {
                return [true, false, 0, 1].indexOf(val) >= 0
            },
            pattern: /bool|true|0|1/
        });
    }])
    .config(function ($logProvider) {
        $logProvider.debugEnabled(process.env.NODE_ENV === 'development');
    })
    .run(function ($rootScope, $location, $window, $http, $state, $translate, $q, $cookies, Language, Auth, Principal, ENV, VERSION, SelectedCompanyService) {
        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        var updateTitle = function (titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        };

        $rootScope.ENV = ENV;
        $rootScope.VERSION = VERSION;
        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;


            // Update the language
            Language.getCurrent().then(function (language) {
                $translate.use(language);
                i18next.changeLanguage(language).then(r => {});
            });

        });


        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title';

            // Remember previous state unless we've been redirected to login or we've just
            // reset the state memory after logout. If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. If we're going
            // to login directly, we don't want to be sent to some previous state anyway
            if (toState.name != 'login' && $rootScope.previousStateName) {
                $rootScope.previousStateName = fromState.name;
                $rootScope.previousStateParams = fromParams;
            }

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            updateTitle(titleKey);
        });

        // if the current translation changes, update the window title
        $rootScope.$on('$translateChangeSuccess', function () {
            updateTitle();
        });


        $rootScope.back = function () {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('home');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };

        $rootScope.selectedCompany = null;
        $rootScope.userCompanies = [];


        $rootScope.getSelectedCompanyIdSync = function () {
            if ($rootScope.selectedCompany) {
                return $rootScope.selectedCompany.id;
            } else {
                return 0;
            }
        }

        $rootScope.getSelectedCompanyId = function () {
            if ($rootScope.selectedCompany &&
                $rootScope.selectedCompany.id &&
                $rootScope.selectedCompany != null &&
                $rootScope.selectedCompany.id > 0) {
                return $rootScope.selectedCompany.id;
            } else {
                return $q.when($rootScope.getSelectedCompany()).then(function (company) {
                    if (company) {
                        return company.id;
                    } else {
                        return 0;
                    }
                });
            }
        };

        $rootScope.getSelectedCompany = function () {
            if ($rootScope.selectedCompany &&
                $rootScope.selectedCompany != null &&
                $rootScope.selectedCompany.id &&
                $rootScope.selectedCompany.id > 0) {
                return $rootScope.selectedCompany;
            } else {
                return null;
            }
        };

        $rootScope.getDefaultCompany = function () {
            return $q.when($rootScope.getUserCompanies()).then(function () {
                if ($rootScope.userCompanies.length > 0) {
                    $rootScope.selectedCompany = $rootScope.userCompanies[0];
                    SelectedCompanyService.setSelectedCompany($rootScope.userCompanies[0]);
                    return $rootScope.selectedCompany;
                } else {
                    return null;
                }
            });
        }
        $rootScope.getCompany = function (company_id) {
            return $http.get("/api/companys/" + company_id).then(function (response) {
                $rootScope.setSelectedCompany(response.data);
                return $rootScope.selectedCompany;
            });
        };
        $rootScope.getUserCompanies = function (refresh) {
            if (refresh == undefined || refresh != undefined && refresh == false) {
                if ($rootScope.userCompanies != undefined) {
                    return $q.when($rootScope.userCompanies);
                }
            }
            return $http.get("/api/companys/user").then(function (response) {
                $rootScope.userCompanies = response.data;
                return $rootScope.userCompanies;
            });
        };


        $rootScope.setSelectedCompany = function (company) {
            $rootScope.selectedCompany = company;
            SelectedCompanyService.setSelectedCompany(company);
            $rootScope.$broadcast("navUpdate");
        };
        $rootScope.$on('$locationChangeSuccess', function () {
            $rootScope.actualLocation = $location.path();
            var refresh = $cookies.get('refresh');
            if (refresh != null) {
                if (refresh !== "/controlroom" && refresh !== "/login" && refresh.substr(0, 9) !== "/survivor" && refresh === $rootScope.actualLocation) {
                    $state.go('home');
                }
            }
        });

        $rootScope.$watch(function () {
            return $location.path()
        }, function (newLocation, oldLocation) {
            if ($rootScope.actualLocation === newLocation) {
                if (newLocation === "/login") {
                    $state.go('home');
                }
            }
        });

        $window.onbeforeunload = function () {
            var expireDate = new Date();
            expireDate.setSeconds(expireDate.getSeconds() + 5);
            $cookies.put('refresh', $location.path(), {'expires': expireDate});
        };

    });
